<template>
<main>
  <CVHero :cv-hero="cvHero" />
  <section class="my-5">
    <h1></h1>
  </section>
  <JobsComponent />
  <SliderComponent />

  
</main>
</template>

<script>
import axios from 'axios'
// @ is an alias to /src
import CVHero from '@/components/CVHero.vue'
import JobsComponent from '@/components/JobsComponent.vue'
import SliderComponent from '@/components/SliderComponent.vue'



export default {
  name: 'CV',
  components: {
    CVHero,
    JobsComponent,
    SliderComponent

  },  
  data() {
    return {
      jobsObj: {},
      cvHero: {},
      api: process.env.VUE_APP_API_CV,
      errors: [],
    }
  },
  async mounted () {
    try {
      const response = await axios.get(this.api)
      this.jobsObj = response.data[0]
      console.log(this.jobsObj)
      this.cvHero = this.jobsObj.acf;
      // console.log(this.$route.params.slug)
    } catch (e) {
      this.errors.push(e)
    }
    
  }
}
</script>
