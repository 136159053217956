<template>
  <section class="mb-16">
    <div class="">
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h1 class="max-w-xlg mb-6 text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            {{cvHero.tagline}} 
          </h1>
          <p class="text-base text-gray-700 md:text-lg">
            {{cvHero.description}}
          </p>
        </div>
        <div class="flex items-center sm:justify-center">
          <a :href="cvHero.cta_link"
            class="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-green-400 hover:bg-green-700 focus:shadow-outline focus:outline-none"
          >
          {{cvHero.cta}}
          </a>
          <a href="/" aria-label="" class="inline-flex items-center font-semibold text-gray-800 transition-colors duration-200 hover:text-green-700">Learn more</a>
        </div>
      </div>
    </div>
    <!-- <div class="relative px-4 sm:px-0">
      <div class="absolute inset-0 bg-gray-100 h-1/2"></div>
      <div class="relative grid mx-auto overflow-hidden bg-white divide-y rounded shadow sm:divide-y-0 sm:divide-x sm:max-w-screen-sm sm:grid-cols-3 lg:max-w-screen-md">
        <div class="inline-block p-8 text-center">
          <div class="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-indigo-50">
            <svg class="w-10 h-10 text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
              <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
            </svg>
          </div>
          <p class="font-bold tracking-wide text-gray-800">Make it better</p>
        </div>
        <div class="inline-block p-8 text-center">
          <div class="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-indigo-50">
            <svg class="w-10 h-10 text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
              <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
            </svg>
          </div>
          <p class="font-bold tracking-wide text-gray-800">Do it faster</p>
        </div>
        <div class="inline-block p-8 text-center">
          <div class="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-indigo-50">
            <svg class="w-10 h-10 text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
              <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
            </svg>
          </div>
          <p class="font-bold tracking-wide text-gray-800">Working harder</p>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "JobsComponent",
  components: {},
  props: {
    cvHero: {
      type: Object
    }
  },
  data() {
    return {
    //   api: process.env.VUE_APP_JOBS,
      api: 'http://wp.scottlind.dk/wp-json/wp/v2/jobs',
      cvObj: {},
      jobsArr: [],
      errors: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(this.api);
      this.jobsArr = response.data;
      console.log(this.jobsArr);
      // console.log(this.$route.params.slug)
    } catch (e) {
      this.errors.push(e);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  max-width: 15em;
}
</style>